<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      label="First Name"
      v-model="form.first_name"
      type="text"
      class="rounded-lg"
      dense
      filled
      :rules="MIXINS_REQUIRED_RULES"
    ></v-text-field>
    <v-text-field
      label="Last Name"
      v-model="form.last_name"
      type="text"
      class="rounded-lg"
      dense
      filled
      :rules="MIXINS_REQUIRED_RULES"
    ></v-text-field>
    <v-text-field
      label="Email"
      v-model="form.email"
      type="email"
      class="rounded-lg"
      dense
      filled
      :rules="[...MIXINS_EMAIL_RULES, ...MIXINS_REQUIRED_RULES]"
    ></v-text-field>
    <v-text-field
      label="Password"
      v-model="form.password"
      :type="viewPassword ? 'text' : 'password'"
      class="rounded-lg"
      dense
      filled
      append-icon="mdi-eye"
      @click:append="viewPassword = !viewPassword"
      :rules="itemId ? [] : MIXINS_REQUIRED_RULES"
    ></v-text-field>
    <v-text-field
      label="Confirm Password"
      v-model="form.password_confirmation"
      :type="viewPassword ? 'text' : 'password'"
      class="rounded-lg"
      dense
      filled
      append-icon="mdi-eye"
      @click:append="viewPassword = !viewPassword"
      :rules="itemId ? [] : MIXINS_REQUIRED_RULES"
    ></v-text-field>
    <location-select-box
      v-model="form.location_id"
      :rules="MIXINS_REQUIRED_RULES"
      item-text="name"
      item-value="id"
      label="Tag Location"
      dense
      filled
    ></location-select-box>
    <user-type-select-box
      v-model="form.user_type"
      :rules="MIXINS_REQUIRED_RULES"
      item-text="name"
      item-value="value"
      label="User Type"
      dense
      filled
    >
    </user-type-select-box>
  </v-form>
</template>

<script>
export default {
  components: {
    UserTypeSelectBox: () => import("../../../components/miscellaneous/selects/UserTypeSelectBox")
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      }
    },
    itemId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    valid: false,
    viewPassword: false
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    }
  }
};
</script>
